import { tw } from '@/utils/tw';
import BaseRowTheme from 'base/components/Row/theme';

const Row = tw.theme({
  extend: BaseRowTheme,
  slots: {
    base: `data-[row-type=courseFilter]:bg-yellow-sea-100 bg-no-repeat data-[row-type=courseFilter]:bg-[url('/image/course/course-filter-blob.svg')]`,
    headline: `-mb-4 group-[.courses]/front:-mb-1 group-[.courses]/front:font-bold sm:text-headline-sm`,
    footer: `[&:not(:first-child)]:mt-5.5`,
  },
  variants: {
    size: {
      primary: {
        base: `group-[.courses]/front:has-[article]:pb-4 group-[.courses]/front:has-[article]:pt-2 group-[.courses]/front:has-[article]:last:mb-12 data-[row-type=courseFilter]:left-1/2 data-[row-type=courseFilter]:w-screen data-[row-type=courseFilter]:max-w-none data-[row-type=courseFilter]:-translate-x-1/2 data-[row-type=courseFilter]:py-8 md:gap-y-6`,
      },
      secondary: {
        base: `group-[.courses]/front:has-[article]:pb-10 group-[.courses]/front:has-[article]:pt-8 group-[.courses]/front:has-[article]:last:mb-12 md:gap-y-6`,
      },
    },
  },
});

export default Row;
