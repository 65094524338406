import { ImageTheme } from '@/components/Image/theme';
import { tw } from '@/utils/tw';

const Image = tw.theme({
  extend: ImageTheme,
  base: `relative block aspect-[2/1] object-cover`,
});

const ArticleTeaserDefault = tw.theme({
  slots: {
    base: `grid w-full container-inline`,
    caption: `text-primary-700 break-words capitalize text-headline-xs`,
    description: `text-body-xs break-words`,
    footer: `max-w-full overflow-hidden`,
    group: `flex min-w-[0] flex-col gap-0.5`,
    header: `max-w-full overflow-hidden`,
    headline: `break-words text-headline-sm`,
  },
  variants: {
    variant: {
      horizontal: {
        base: `grid-cols-2 gap-3`,
        caption: ``,
        description: `sm:container-col-lg-7:text-body-md sm:container-col-lg-7:mt-3`,
        footer: `col-span-full`,
        group: ``,
        header: `col-span-2`,
        headline: `sm:container-col-lg-7:text-headline-lg`,
      },
      vertical: {
        base: `auto-rows-min gap-4`,
        caption: ``,
        description: `container-col-sm-7:text-body-md sm:text-body-md mt-1 sm:container-col-lg-4:mt-2 sm:container-col-lg-7:mt-3`,
        footer: ``,
        group: ``,
        header: ``,
        headline: `container-col-sm-7:text-headline sm:text-headline sm:container-col-lg-4:text-headline-lg sm:container-col-lg-7:text-headline-xl`,
      },
    },
  },
  defaultVariants: {
    variant: 'vertical',
  },
});

export default Object.assign(ArticleTeaserDefault, { Image });
