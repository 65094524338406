import { ButtonProps } from '@/components/Button';
import { componentTheme } from '@/styles/Row';
import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { ReactNode } from 'react';
import { Row } from './Row';

export interface StandaloneRowProps extends StandaloneComponentProps<typeof Row> {
  button?: Pick<ButtonProps, 'content' | 'link'>;
  caption?: ReactNode;
  content?: ReactNode;
  footer?: ReactNode;
  headline?: ReactNode;
}

export const StandaloneRow: StandaloneComponent<StandaloneRowProps> = ({
  button,
  caption,
  content,
  footer,
  headline,
  ...props
}) => {
  return (
    <Row {...props}>
      {headline && <Row.Headline>{headline}</Row.Headline>}
      {caption && <Row.Caption>{caption}</Row.Caption>}
      {content}
      {(footer || button) && (
        <Row.Footer>
          {footer}
          {button && (
            <Row.Button
              content={button.content}
              link={button.link}
              options={{ theme: componentTheme('button'), ...props.options?.$button }}
            />
          )}
        </Row.Footer>
      )}
    </Row>
  );
};
