import { tw } from '@/utils/tw';

const Adunit = tw.theme({
  slots: {
    base: `ad-row-column relative z-10 mb-6 mt-6 w-full bg-gray-200 text-center font-sans text-xs leading-6.5 wings wings-gray-200 md:mb-0 md:p-0`,
    headline: ``,
    wrapper: `box-content flex min-h-[320px] items-start justify-center overflow-visible pb-3 sm:min-h-[240px]`,
    placement: `flex h-full w-full items-start justify-center md:p-0`,
  },
});

export default Adunit;
