import { Row, RowProps } from '@/components/Row';
import c from '@/styles/Search';
import { StandaloneComponent } from '@/types/component';
import { cn } from '@/utils/cn';
import { getPropStylesFactory } from '@/utils/getPropStylesFactory';
import { SearchItemComponent } from './Search.Item';

export interface SearchProps extends RowProps {}

const SearchComponent: StandaloneComponent<SearchProps> = ({ content, options, ...props }) => {
  const { $button, $caption, $footer, $headline, colors, size, variant, className, ...baseProps } = (options ??
    {}) as any;

  const {
    colors: buttonColors,
    size: buttonSize,
    variant: buttonVariant,
    className: buttonClassName,
    ...buttonProps
  } = $button ?? {};

  const {
    colors: captionColors,
    size: captionSize,
    variant: captionVariant,
    className: captionClassName,
    ...captionProps
  } = $caption ?? {};

  const {
    colors: footerColors,
    size: footerSize,
    variant: footerVariant,
    className: footerClassName,
    ...footerProps
  } = $footer ?? {};

  const {
    colors: headlineColors,
    size: headlineSize,
    variant: headlineVariant,
    className: headlineClassName,
    ...headlineProps
  } = $headline ?? {};

  const getPropStyles = getPropStylesFactory(c);

  const buttonColorsClassName = getPropStyles('button_colors', buttonColors, 'primary');
  const buttonSizeClassName = getPropStyles('button_size', buttonSize, 'medium');
  const buttonVariantClassName = getPropStyles('button_variant', buttonVariant, 'default');
  const buttonComponentClassName = cn(
    buttonColorsClassName,
    buttonSizeClassName,
    buttonVariantClassName,
    buttonClassName,
  );

  const captionColorsClassName = getPropStyles('caption_colors', captionColors, 'default');
  const captionSizeClassName = getPropStyles('caption_size', captionSize, 'default');
  const captionVariantClassName = getPropStyles('caption_variant', captionVariant, 'default');
  const captionComponentClassName = cn(
    captionColorsClassName,
    captionSizeClassName,
    captionVariantClassName,
    captionClassName,
  );

  const footerColorsClassName = getPropStyles('footer_colors', footerColors, 'default');
  const footerSizeClassName = getPropStyles('footer_size', footerSize, 'default');
  const footerVariantClassName = getPropStyles('footer_variant', footerVariant, 'default');
  const footerComponentClassName = cn(
    footerColorsClassName,
    footerSizeClassName,
    footerVariantClassName,
    footerClassName,
  );

  const headlineColorsClassName = getPropStyles('headline_colors', headlineColors, 'default');
  const headlineSizeClassName = getPropStyles('headline_size', headlineSize, 'default');
  const headlineVariantClassName = getPropStyles('headline_variant', headlineVariant, 'default');
  const headlineComponentClassName = cn(
    headlineColorsClassName,
    headlineSizeClassName,
    headlineVariantClassName,
    headlineClassName,
  );

  const colorsClassName = getPropStyles('colors', colors, 'default');
  const sizeClassName = getPropStyles('size', size, 'default');
  const variantClassName = getPropStyles('variant', variant, 'default');
  const componentClassName = cn(colorsClassName, sizeClassName, variantClassName, className);

  return (
    <Row
      content={content}
      options={{
        $button: {
          colors: buttonColors,
          size: buttonSize,
          variant: buttonVariant,
          className: buttonComponentClassName,
          ...buttonProps,
        },
        $caption: {
          colors: captionColors,
          size: captionSize,
          variant: captionVariant,
          className: captionComponentClassName,
          ...captionProps,
        },
        $footer: {
          colors: footerColors,
          size: footerSize,
          variant: footerVariant,
          className: footerComponentClassName,
          ...footerProps,
        },
        $headline: {
          colors: headlineColors,
          size: headlineSize,
          variant: headlineVariant,
          className: headlineComponentClassName,
          ...headlineProps,
        },
        colors,
        size,
        variant,
        className: componentClassName,
        ...baseProps,
      }}
      {...props}
    />
  );
};

export const Search = Object.assign(SearchComponent, {
  Item: SearchItemComponent,
});
