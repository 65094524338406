import style from '@/mabra/components/standalone/Badge/style';
import { cn } from '@/utils/cn';
import { ArticleBadgeData } from 'lib/labrador/articleBadge';
import { isEmpty } from 'lodash-es';
import NextImage, { ImageProps as NextImageProps } from 'next/image';
import { FC } from 'react';

export type MabraBadgeVariant = 'header' | 'teaser' | 'bodytext';
export type MabraBadgeColors = 'first' | 'second' | 'third' | 'fourth' | 'fifth';
export const badgeColorConfig: Record<string, MabraBadgeColors> = {
  'traningsexperten-svarar': 'second',
  'kbt-terapeuten-svarar': 'third',
  'doktorn-svarar': 'fourth',
  'relationsexperten-svarar': 'fifth',
  'psykologen-svarar': 'third',
};

export interface MabraStandaloneBadgeProps {
  data?: ArticleBadgeData;
  variant: MabraBadgeVariant;
  options?: {
    $image?: Partial<NextImageProps>;
  };
}

export const MabraStandaloneBadge: FC<MabraStandaloneBadgeProps> = ({ data, variant, options, ...props }) => {
  const { $image } = options ?? {};

  if (isEmpty(data)) {
    return null;
  }

  const colors = badgeColorConfig[data.type] || 'first';
  const { name, text: title, imageUrl, value } = data ?? {};

  const isGeneral = value === 'expert-general';
  const image = isGeneral ? null : imageUrl || `/image/badges/${value}.png`;

  const variantClassName = style['variant_default'];
  const sizeClassName = style[`size_${variant}`];
  const className = cn(variantClassName, sizeClassName);

  const imageVariantClassName = style[`image_variant_${variant}`];
  const imageSizeClassName = style[`image_size_${variant}`];
  const imageClassName = cn(imageVariantClassName, imageSizeClassName);

  const titleVariantClassName = style[`title_variant_${variant}`];
  const titleColorsClassName = style[`title_colors_${colors}`];
  const titleSizeClassName = style['title_size_default'];
  const titleClassName = cn(titleColorsClassName, titleSizeClassName, titleVariantClassName);

  const nameVariantClassName = style[`name_variant_${variant}`];
  const nameColorsClassName = style[`name_colors_${colors}`];
  const nameSizeClassName = style['name_size_default'];
  const nameClassName = cn(nameColorsClassName, nameSizeClassName, nameVariantClassName);

  return (
    <div data-image={!!image} className={className} {...props}>
      {image && (
        <NextImage src={image} alt="Råd från en expert" width={60} height={60} className={imageClassName} {...$image} />
      )}
      <div className="flex flex-col justify-center">
        <span className={titleClassName}>{title}</span>
        {name && !isGeneral && <span className={nameClassName}>{name}</span>}
      </div>
    </div>
  );
};
