import { AdunitTheme } from '@/components/Adunit/theme';
import { GenericSlotFactory } from 'base/components/GenericSlot';

const $ = GenericSlotFactory({ theme: AdunitTheme });

const Base = $({ as: 'div', slot: 'base' });
const Headline = $({ as: 'span', slot: 'headline' });
const Wrapper = $({ as: 'div', slot: 'wrapper' });
const Placement = $({ as: 'div', slot: 'placement' });

export const Adunit = Object.assign(Base, {
  Headline,
  Wrapper,
  Placement,
});
