import { ArticleTeaserDefault, ArticleTeaserDefaultProps } from '@/components/ArticleTeaser/Default';
import c from '@/styles/Search';
import { StandaloneComponent } from '@/types/component';
import { cn } from '@/utils/cn';
import { getPropStylesFactory } from '@/utils/getPropStylesFactory';

export interface SearchItemProps extends ArticleTeaserDefaultProps {}

export const SearchItemComponent: StandaloneComponent<SearchItemProps> = ({ options, image, ...props }) => {
  const { $caption, $description, $group, $headline, variant, className, ...baseProps } = options ?? {};

  const { className: captionClassName, ...captionProps } = $caption ?? {};

  const { className: descriptionClassName, ...descriptionProps } = $description ?? {};

  const { className: groupClassName, ...groupProps } = $group ?? {};

  const { className: headlineClassName, ...headlineProps } = $headline ?? {};

  const getPropStyles = getPropStylesFactory(c);

  const captionColorsClassName = getPropStyles('item_caption_colors', 'default');
  const captionVariantClassName = getPropStyles('item_caption_variant', $caption?.variant, 'horizontal');
  const captionSizeClassName = getPropStyles('item_caption_size', $caption?.variant, 'horizontal');
  const captionComponentClassName = cn(
    captionColorsClassName,
    captionVariantClassName,
    captionSizeClassName,
    captionClassName,
  );

  const descriptionColorsClassName = getPropStyles('item_description_colors', 'default');
  const descriptionVariantClassName = getPropStyles('item_description_variant', $description?.variant, 'horizontal');
  const descriptionSizeClassName = getPropStyles('item_description_size', $description?.variant, 'horizontal');
  const descriptionComponentClassName = cn(
    descriptionColorsClassName,
    descriptionVariantClassName,
    descriptionSizeClassName,
    descriptionClassName,
  );

  const groupColorsClassName = getPropStyles('item_group_colors', 'default');
  const groupVariantClassName = getPropStyles('item_group_variant', $group?.variant, 'horizontal');
  const groupSizeClassName = getPropStyles('item_group_size', $group?.variant, 'horizontal');
  const groupComponentClassName = cn(groupColorsClassName, groupVariantClassName, groupSizeClassName, groupClassName);

  const headlineColorsClassName = getPropStyles('item_headline_colors', 'default');
  const headlineVariantClassName = getPropStyles('item_headline_variant', $headline?.variant, 'horizontal');
  const headlineSizeClassName = getPropStyles('item_headline_size', $headline?.variant, 'horizontal');
  const headlineComponentClassName = cn(
    headlineColorsClassName,
    headlineVariantClassName,
    headlineSizeClassName,
    headlineClassName,
  );

  const imageColorsClassName = getPropStyles('item_image_colors', 'default');
  const imageVariantClassName = getPropStyles('item_image_variant', 'horizontal');
  const imageSizeClassName = getPropStyles('item_image_size', 'horizontal');
  const imageComponentClassName = cn(imageColorsClassName, imageVariantClassName, imageSizeClassName);

  const colorsClassName = getPropStyles('item_colors', 'default');
  const variantClassName = getPropStyles('item_variant', variant, 'horizontal');
  const sizeClassName = getPropStyles('item_size', variant, 'horizontal');
  const componentClassName = cn(colorsClassName, variantClassName, sizeClassName, className);

  return (
    <ArticleTeaserDefault
      image={{
        ...image,
        options: {
          className: imageComponentClassName,
        },
      }}
      options={{
        $caption: {
          className: captionComponentClassName,
          ...captionProps,
        },
        $description: {
          className: descriptionComponentClassName,
          ...descriptionProps,
        },
        $group: {
          className: groupComponentClassName,
          ...groupProps,
        },
        $headline: {
          className: headlineComponentClassName,
          ...headlineProps,
        },
        variant: variant ?? 'horizontal',
        className: componentClassName,
        ...baseProps,
      }}
      {...props}
    />
  );
};
