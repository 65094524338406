import { useAppState } from '@/hooks/useAppState';
import type { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { setAdPlacementLoaded } from '@/utils/adunit';
import { useEffect } from 'react';
import { Adunit } from './Adunit';

export interface StandaloneAdunitProps extends StandaloneComponentProps<typeof Adunit> {
  title: React.ReactNode;
  data: {
    elID?: string;
    placement?: string;
  };
}

export const StandaloneAdunit: StandaloneComponent<StandaloneAdunitProps> = ({ title, data, ...props }) => {
  const [{ isSecondNativeArticleLoaded }] = useAppState();
  const { elID, placement } = data ?? {};

  useEffect(() => {
    if (placement && elID) {
      setAdPlacementLoaded(elID, placement);
    }
  }, [placement, elID]);

  if (placement === 'responsives_2' && isSecondNativeArticleLoaded) {
    return null;
  }

  return (
    <Adunit {...props}>
      <Adunit.Headline>{title}</Adunit.Headline>
      <Adunit.Wrapper>
        <Adunit.Placement id={elID} ad-placement={placement} suppressHydrationWarning />
      </Adunit.Wrapper>
    </Adunit>
  );
};
