import { ImageTheme } from '@/components/Image/theme';
import { tw } from '@/utils/tw';
import BaseArticleTeaserDefaultTheme from 'base/components/ArticleTeaser/Default/theme';

const Image = tw.theme({
  extend: ImageTheme,
  base: `aspect-default`,
});

const ArticleTeaserDefault = tw.theme({
  extend: BaseArticleTeaserDefaultTheme,
  slots: {
    caption: `text-gray-600`,
    description: `text-gray-600 text-headline-xs`,
  },
  variants: {
    variant: {
      horizontal: {
        base: `gap-6 sm:grid-cols-5`,
        caption: `group-[.courses]/front:hidden`,
        description: `mt-2 sm:container-col-lg-7:mt-2 sm:container-col-lg-7:text-headline-sm`,
        group: `col-span-2 sm:col-span-3`,
        headline: `text-headline-md sm:container-col-lg-4:text-headline-sm`,
      },
      vertical: {
        base: `gap-2 data-[full-width]:-ml-[calc(50vw-50%)] data-[full-width]:w-screen`,
        caption: `group-[.bg]/box:mx-4 group-[.courses]/front:hidden`,
        description: `mt-0.75 container-col-sm-7:text-headline-xs sm:text-headline-xs sm:container-col-lg-4:mt-0.75 sm:container-col-lg-7:mt-1 sm:container-col-lg-7:text-headline-sm`,
        headline: `group-[.bg]/box:mx-4 group-[.bg]/box:mb-4 container-col-sm-7:text-headline-sm sm:text-headline-sm sm:container-col-lg-4:text-headline-sm sm:container-col-lg-5:text-headline sm:container-col-lg-7:text-headline-lg`,
      },
    },
  },
});

export default Object.assign(ArticleTeaserDefault, { Image });
