import { isServer } from '@/utils/isServer';

export const setAdPlacementLoaded = (elID: string, placement: string) => {
  if (isServer()) return;

  const uniqueId = elID?.split('-').pop();
  if (window && uniqueId) {
    window.loadedAdPlacement = window.loadedAdPlacement || {};
    window.loadedAdPlacement[uniqueId] = window.loadedAdPlacement[uniqueId] || {};
    window.loadedAdPlacement[uniqueId][placement] = 1;
  }
};
