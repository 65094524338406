import { Adunit } from '@/components/Adunit';
import { Spinner } from '@/components/Spinner';
import { BylineDataResult } from '@/types/bylineData';
import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { SearchData, SearchResult } from '@/types/search';
import { getDomain } from '@/utils/getDomain';
import { getUrl } from '@/utils/getUrl';
import { logger } from '@/utils/logger';
import { ErrorNode } from 'base/components/ErrorBoundary';
import { getAdUnit, shouldInjectAdAtRow } from 'lib/data/compute/ads/helpers';
import { Fragment, ReactNode, useCallback, useMemo, useState } from 'react';
import { Search, SearchProps } from './Search';
import { SearchItemProps } from './Search.Item';

const AUTHOR_PAGE = 'authorPage';

export const fetchSearchResults = async (query: string, next?: number, filter?: string) => {
  const host = getDomain();
  const url = getUrl(`/api/search`, host);

  if (!url) {
    logger.error('Search: Could not construct URL');
    return {};
  }

  url?.searchParams.set('term', query);
  url?.searchParams.set('start', `${next ?? 1}`);
  url?.searchParams.set('filter', filter ?? '');

  try {
    const response = await fetch(url.href);
    if (response.status !== 200) {
      logger.error('Search: API returned ' + response.status);
      return {};
    }
    return response.json();
  } catch {
    logger.catch('Search: There was an unknown error.');
    return {};
  }
};

export type StandaloneSearchProps = StandaloneComponentProps & {
  initial?: {
    results?: SearchResult[] | BylineDataResult[];
    next?: number;
  };
  query?: string;
  adsUniqueId?: string;
  total?: number;
  pageType?: typeof AUTHOR_PAGE | 'searchResults';
  headline?: ReactNode;
  button?: ReactNode;
  options?: SearchProps & {
    $item?: SearchItemProps;
  };
  page?: number;
  hideButton?: boolean;
};

const getResults = (query: string, next: number, callback: () => void): Promise<SearchData> => {
  return fetchSearchResults(query, next).catch((error) => {
    logger.catch(error);
    return callback();
  });
};

export const StandaloneSearch: StandaloneComponent<StandaloneSearchProps> = ({
  initial,
  query,
  adsUniqueId = '',
  total,
  headline,
  button,
  options,
  pageType,
  ...props
}) => {
  const [results, setResults] = useState<SearchResult[]>(initial?.results ?? []);
  const [next, setNext] = useState(initial?.next ?? 1);
  const [isError, setError] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const { $item: itemProps, ...baseProps } = options ?? {};

  const onLoadMore = useCallback(async () => {
    if (isLoading) return;

    if (!query || !next) {
      setNext(0);
      return logger.error(`Load more search results have failed.`, { query, next });
    }

    setLoading(true);
    const response = await getResults(query, next, () => setError(true));

    if (!response || response.status !== 'search_successful') {
      logger.error(response);
      return setError(true);
    }

    setNext(response.nextPage || 0);

    setResults([...results, ...response.items]);

    setLoading(false);
  }, [isLoading, next, query, results]);

  const defaultHeadline: SearchProps['headline'] = useMemo(() => {
    if (pageType === AUTHOR_PAGE) {
      return (
        <h1>
          Artiklar av <em>{query || '?'}</em>
        </h1>
      );
    }

    return (
      <h1>
        Sökresultat för <em>{query || '?'}</em>
      </h1>
    );
  }, [query, pageType]);

  let adPlacementNo = 1;

  const caption: SearchProps['caption'] = `Hittade ${total || 0} resultat`;

  const content: SearchProps['content'] = (
    <>
      {results?.map((item, index) => (
        <Fragment key={index}>
          <Search.Item
            headline={item.title}
            caption={item.date}
            description={item.snippet}
            image={{ src: item.image, alt: item.title }}
            links={{ article: { href: item.link } }}
          />
          {shouldInjectAdAtRow({ currentRow: index, frequency: 3 }) && (
            <Adunit data={getAdUnit('responsive', adPlacementNo++, adsUniqueId)?.data} title={'Annons'} />
          )}
        </Fragment>
      ))}
      {isLoading && <Spinner />}
    </>
  );

  if (isError) {
    return ErrorNode;
  }

  const isButtonDisabled = isLoading || isError;
  const showHeadline = props.page ? (props.page === 1 && pageType === AUTHOR_PAGE ? true : false) : true;
  return (
    <Search
      button={{ content: button ?? 'Ladda mer' }}
      options={{
        $button: {
          className: !next || props.hideButton ? 'hidden' : '',
          disabled: isButtonDisabled,
          onClick: onLoadMore,
        },
      }}
      headline={showHeadline && (headline || defaultHeadline)}
      {...{ caption, content }}
      {...baseProps}
      {...props}
    />
  );
};
