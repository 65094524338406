/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import { getPropStylesFactory } from '@/utils/getPropStylesFactory';
import { getComponentStylesFactory } from '@/utils/getComponentStylesFactory';
import { getComponentThemeFactory } from '@/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "item_caption_variant_horizontal": "hidden",
  "item_caption_variant_vertical": "hidden",
  "item_caption_colors_default": "",
  "item_caption_size_horizontal": "",
  "item_caption_size_vertical": "",
  "item_description_variant_horizontal": "",
  "item_description_variant_vertical": "",
  "item_description_colors_default": "",
  "item_description_size_horizontal": "",
  "item_description_size_vertical": "",
  "item_group_variant_horizontal": "",
  "item_group_variant_vertical": "",
  "item_group_colors_default": "",
  "item_group_size_horizontal": "",
  "item_group_size_vertical": "",
  "item_headline_variant_horizontal": "",
  "item_headline_variant_vertical": "",
  "item_headline_colors_default": "",
  "item_headline_size_horizontal": "",
  "item_headline_size_vertical": "",
  "item_image_variant_horizontal": "",
  "item_image_variant_vertical": "",
  "item_image_colors_default": "",
  "item_image_size_horizontal": "",
  "item_image_size_vertical": "",
  "item_variant_horizontal": "",
  "item_variant_vertical": "",
  "item_colors_default": "",
  "item_size_horizontal": "",
  "item_size_vertical": "",
  "button_colors_default": "",
  "button_size_default": "",
  "button_variant_default": "",
  "caption_colors_default": "",
  "caption_size_default": "",
  "caption_variant_default": "",
  "footer_colors_default": "",
  "footer_size_default": "",
  "footer_variant_default": "",
  "headline_colors_default": "[&_em]:text-gray-500",
  "headline_size_default": "",
  "headline_variant_default": "",
  "colors_default": "",
  "size_default": "p-6",
  "variant_default": ""
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "Search");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;