import { ArticleTeaserDefaultTheme } from '@/components/ArticleTeaser/Default/theme';
import { Image as StandaloneImage } from '@/components/Image';
import { GenericSlot, GenericSlotFactory } from 'base/components/GenericSlot';

const $ = GenericSlotFactory({ theme: ArticleTeaserDefaultTheme });

const Base = $({ as: 'article', slot: 'base' });
const Caption = $({ as: 'div', slot: 'caption' });
const Description = $({ as: 'div', slot: 'description' });
const Footer = $({ as: 'div', slot: 'footer' });
const Group = $({ as: 'div', slot: 'group' });
const Header = $({ as: 'div', slot: 'header' });
const Headline = $({ as: 'h2', slot: 'headline' });
const Image = GenericSlot({ as: StandaloneImage, theme: ArticleTeaserDefaultTheme.Image });

export const ArticleTeaser = Object.assign(Base, {
  Caption,
  Description,
  Footer,
  Group,
  Header,
  Headline,
  Image,
});
